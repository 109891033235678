import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import Sidebar from "../components/sidebar";
import useMetadata from "../components/hooks/useMetadata";
import { jsx, css } from "@emotion/react";
import { colors, Button, A } from "../style/theme";
import { GiHamburgerMenu } from "react-icons/gi";
import { mq } from "../utils/helper";
import "../style/normalize.css";
import "../style/global.css";

const Li = styled.li({
  margin: "0 0.9rem",
  transition: "0.25s",
  padding: "0.3rem",
  ": hover": { backgroundColor: colors.green, borderRadius: "5px" },
});

function MobileMenu() {
  return (
    <div
      css={{
        display: "none",
        [mq[1]]: {
          padding: "1rem",
          backgroundColor: colors.dark,
          marginRight: "auto",
          margintop: "auto",
          marginBottom: "auto",
          display: "block",
        },
      }}
    >
      <ul
        css={{
          [mq[1]]: {
            display: "block",
            margin: 0,
            listStyle: "none",
            "& a": {
              fontSize: "1.1rem",
              color: colors.white,
            },
          },
        }}
      >
        <Li>
          <A as={Link} to="/fahras">
            الفهرس
          </A>
        </Li>
        <Li>
          <A as={Link} to="/blog">
            المدوّنة
          </A>
        </Li>
        <Li>
          <A as={Link} to="/about">
            حول
          </A>
        </Li>
      </ul>
      <Button
        as={Link}
        to="/add-blog"
        css={{
          display: "block",
          backgroundColor: colors.red,
          padding: "0.4rem",
          marginRight: "1rem",
        }}
      >
        أضف مدوّنة
      </Button>
    </div>
  );
}

function Header() {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  return (
    <header
      css={{
        width: "100%",
        marginBottom: "1.5rem",
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "1200px",
          margin: "auto",
          paddingTop: "1rem",
          paddingBottom: "0.5rem",
          [mq[3]]: {
            maxWidth: "1000px",
          },
          [mq[1]]: {
            padding: 0,
            margin: "0.5rem 0.9rem",
          },
        }}
      >
        <h1
          css={{
            textAlign: "center",
            margin: 0,
            [mq[1]]: {
              textAlign: "right",
              width: "100%",
              margin: 0,
              padding: 0,
              paddingRight: 0,
            },
          }}
        >
          <A as={Link} to="/">
            <img
              src="/assets/logo-dark.png"
              alt="Elfehrest Logo"
              css={{
                marginBottom: 0,
                width: "170px",
                [mq[1]]: {
                  width: "120px",
                },
              }}
            />
          </A>
        </h1>

        <ul
          css={{
            marginRight: "auto",
            marginTop: "auto",
            marginBottom: "auto",
            display: "flex",
            justifyContent: "center",
            listStyle: "none",
            fontSize: "1.4rem",
            [mq[1]]: {
              display: "none",
            },
          }}
        >
          <Li>
            <A as={Link} to="/fahras">
              الفهرس
            </A>
          </Li>
          <Li>
            <A as={Link} to="/blog">
              المدوّنة
            </A>
          </Li>
          <Li>
            <A as={Link} to="/about">
              حول
            </A>
          </Li>
        </ul>
        <Button
          as={Link}
          to="/add-blog"
          css={{
            margin: 0,
            marginBottom: "0.3rem",
            textDecoration: "none",
            backgroundColor: colors.red,

            [mq[1]]: {
              display: "none",
            },
          }}
        >
          أضف مدوّنة
        </Button>

        <div
          css={{
            display: "none",
            marginRight: "2rem",

            [mq[1]]: {
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              fontSize: "100%",
              cursor: "pointer",
            },
          }}
          onClick={() => setIsMenuToggled(!isMenuToggled)}
        >
          <GiHamburgerMenu css={{ color: colors.dark }} />
        </div>
      </div>
      <div
        css={{
          borderBottom: `1px solid ${colors.dark}`,
          margin: "auto",
          width: "70%",
          [mq[3]]: {
            width: "95%",
          },
        }}
      ></div>
      {isMenuToggled && <MobileMenu />}
    </header>
  );
}

function Footer() {
  const { title, about } = useMetadata();
  return (
    <footer
      css={{
        textAlign: "center",
        color: colors.white,
        width: "100%",
        padding: "1.5rem",
        backgroundColor: colors.dark,
      }}
    >
      <div
        css={{
          margin: "auto",
          maxWidth: "600px",
        }}
      >
        <h1
          css={{
            margin: "0",
            [mq[1]]: {
              margin: 0,
              padding: "1rem",
            },
          }}
        >
          <A as={Link} to="/">
            <img
              src="/assets/logo.png"
              alt="Elfehrest Logo"
              css={{
                marginBottom: 0,
                width: "170px",
                [mq[1]]: {
                  width: "120px",
                },
              }}
            />
          </A>
        </h1>
        <p
          css={{
            marginTop: "1rem",
            [mq[1]]: {
              marginTop: 0,
            },
          }}
        >
          {about}
        </p>
        <ul
          css={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "1200px",
            margin: "auto",
            listStyle: "none",
            fontSize: "1.4rem",
            "& a": {
              color: colors.white,
            },
            "& li": {
              marginLeft: "2rem",
            },
            "& li:last-of-type": {
              marginLeft: 0,
            },
          }}
        >
          <li>
            <A as={Link} to="/fahras">
              الفهرس
            </A>
          </li>
          <li>
            <A as={Link} to="/blog">
              المدوّنة
            </A>
          </li>
          <li>
            <A as={Link} to="/about">
              حول
            </A>
          </li>
        </ul>
        <p css={{ marginTop: "1rem", marginBottom: 0 }}>
          بعض الحقوق محفوظة - {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
}

function Layout({ children }) {
  return (
    <>
      <Header />
      <main>
        <div
          css={{
            maxWidth: "1200px",
            margin: "auto",
            display: "grid",
            gridTemplateColumns: "1.7fr 1fr",
            gridTemplateAreas: "'posts widgets'",
            gridColumnGap: "1rem",
            [mq[1]]: {
              display: "block",
              margin: "1rem",
            },
            [mq[3]]: {
              margin: "1rem",
            },
          }}
        >
          <div
            css={{
              gridArea: "posts",
            }}
          >
            {children}
          </div>
          <Sidebar
            css={{
              gridArea: "widgets",
            }}
          />
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Layout;
